import { FC } from "react";
import ListDecks from "./ListDecks/ListDecks";

const Apprend: FC = () => {


    return (
        <div>
            <ListDecks />
        </div>
        );
}


export default Apprend;