import SideBar from "./components/SideBar";

const Apprend = () => {
    return (
        <div>
            <h1>Apprend</h1>
            <SideBar />
        </div>
        );
}


export default Apprend;